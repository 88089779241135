/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import GoogleAdManagerContext from "providers/GoogleAdManagerProvider/GoogleAdManagerContext";
import { GoogleAdPlacementPosition } from "types/GoogleAdPlacementPosition";
import useWindowLocation from "hooks/useWindowLocation";

import styles from "./GoogleAdPlacement.module.scss";
import randomElementId from "./randomElementId";
import { useConsent } from "components/CookieConsent/CookieConsentContext";

const pregnancyWeekByWeekPaths = Array.from(
  { length: 42 },
  (_, i) => i === 0
    ? `blog/1-week-pregnant`
    : `blog/${i + 1}-weeks-pregnant`
);

// This could be API driven, but it changes infrequently.
export const BLACKLIST = [
  "/blog/peanut-time100-most-influential-companies-2022",
  "/blog/peanut-apples-best-apps-of-2021",
  "/blog/peanut-pro",
  "/blog/peanut-pods",
  "/blog/peanut-groups-how-to",
  "/blog/reframing-revolution",
  "/blog/premium-content",
  "/blog/zapp-changed-the-way-i-shop",
  "/blog/relational-self-awareness",
  "/blog/peanut-success-story-tia-marie",
  "/blog/photobox-essentials-for-new-mums",
  "/blog/michelle-kennedy-life-as-working-mum",
  "/blog/expectations-of-working-mums-are-outdated",
  "/blog/working-mum-quotes",
  "/blog/tips-for-working-mums",
  "/blog/how-to-be-a-working-mum",
  "/blog/going-back-to-work-after-baby",
  "/blog/self-care-ideas-for-mums",
  "/blog/work-from-home-with-baby",
  "/blog/pumping-at-work",
  "/blog/maternity-leave-out-of-office-messages",
  "/blog/things-to-do-on-maternity-leave",
  "/blog/my-career-pause",
  "/blog/when-to-tell-work-youre-pregnant",
  "/blog/understanding-mum-guilt",
  "/blog/mum-burnout",
  "/blog/mum-anxiety",
  "/blog/mum-stress",
  "/blog/mental-health-during-pregnancy-new-mum",
  "/blog/healing-mum-burnout",
  "/blog/self-care-for-mums",
  "/blog/how-to-find-a-nanny",
  "/blog/how-much-does-a-nanny-cost",
  "/blog/nanny-interview-questions",
  "/blog/questions-to-ask-daycare",
  "/blog/how-to-reduce-food-waste-at-home",
  ...pregnancyWeekByWeekPaths,
  "/es/blog/cuidado-de-la-piel-del-recien-nacido",
];

export interface Props {
  className?: string;
  position: GoogleAdPlacementPosition;
}

function GoogleAdPlacement({ className, position }: Props): JSX.Element {
  const ref = useRef(null);
  const { addPlacement } = useContext(GoogleAdManagerContext);

  const windowLocation = useWindowLocation();

  const { pathname } = windowLocation();

  // Create a one of id for the dom element
  const elementId = useMemo(() => randomElementId(), []);

  useEffect(() => {
    // If the dom element is not ready, return
    if (!ref.current || ref.current === null) return;
    // When the dom element is ready, set the ad in the provider.
    addPlacement(position, elementId);
  }, [ref.current]);

  return BLACKLIST.includes(pathname) ? (
    <></>
  ) : (
    <div
      id={elementId}
      data-testid="google-ad-placement"
      ref={ref}
      className={classNames(styles.container, className)}
    />
  );
}

export default GoogleAdPlacement;
